import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { logo, logoAd, channelAd },
        } = this.props;

        return (
            <React.Fragment>
                <a href={logo.url} target="_blank" rel={rel}>
                    <Chip id="50105" type="struct" title="Logo" groupName="头部" content={logo}>
                        <img src={logo.src} alt={logo.title} width={logo.width} height={logo.height} />
                    </Chip>
                </a>
                <div className={styles.ad_1}>
                    <Chip id="50106" type="struct" title="LogoAd" groupName="头部" content={logoAd}>
                        <img src={logoAd.src} width={logoAd.width} height={logoAd.height} />
                    </Chip>
                </div>
                {/* <Ad content={channelAd} styleName={styles.ad_2} /> */}
            </React.Fragment>
            // <React.Fragment>
            //     <a href="http://home.ifeng.com/" target="_blank" rel={rel}>
            //         <img
            //             src="https://p3.ifengimg.com/37780e23b9ea2d8b/2017/38/logoHome.png"
            //             alt="凤凰网家居"
            //             width="161"
            //             height="27"
            //         />
            //     </a>
            //     <div className={styles.ad_1}>
            //         <img
            //             src="https://p0.ifengimg.com/37780e23b9ea2d8b/2017/38/slogen_home.jpg"
            //             width="177"
            //             height="27"
            //         />
            //     </div>
            //     {/* <Ad content={channelAd} styleName={styles.ad_2} /> */}
            // </React.Fragment>
        );
    }
}

export default errorBoundary(Logo);
