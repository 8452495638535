import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../../../../components/recommend';
/**
 * 定义 Xlogo 组件
 */

class Xlogo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const constentList = [];

        for (let i in content) {
            constentList.push(content[i]);
        }

        return (
            <div className={styles.mod_lm_title}>
                <Recommend content={constentList[0]} />
            </div>
        );
    }
}

export default errorBoundary(Xlogo);
