import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import Recommend from '../../../../../components/recommend';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
/**
 * 定义 Xlist 组件
 */

class Xlist extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        num: PropTypes.number,
        currentIndex: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            isData: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.num === nextProps.currentIndex) {
            this.setState({
                hover: true,
            });
        }

        if (nextProps.num !== nextProps.currentIndex) {
            this.setState({
                hover: false,
            });
        }
    }

    UNSAFE_componentWillMount() {
        // console.log('logo', this.props.content.logo);
        if (this.props.content.logo && this.props.content.logo.length > 4) {
            this.setState({
                isData: true,
            });
        } else {
            console.log(`第${this.props.num}个 xlist 组件数据有问题，无带图数据`);

            return;
        }

        if (this.props.num === this.props.currentIndex) {
            this.setState({
                hover: true,
            });
        }

        if (this.props.num !== this.props.currentIndex) {
            this.setState({
                hover: false,
            });
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const {
            state: { hover },
        } = this;

        const constentList = [];

        for (const i in content) {
            constentList.push(content[i]);
        }

        const xNav = (
            <p>
                <a href="#" target="_blank" className={styles.title} rel={rel}>
                    {content.mainTitle.title}
                </a>
            </p>
        );

        const title = (item, index) => {
            return (
                <a key={index} href={item.url} target=" _blank" rel={rel} className={index === 0 ? styles.diff : ''}>
                    {item.title}&nbsp;|&nbsp;
                </a>
            );
        };

        const slideArr = [];
        const logolist = content.logo;
        const len = (logolist && logolist.length) || 0;

        for (let i = 0; i < len; i += 4) {
            slideArr.push(content.logo.slice(i, i + 4));
        }

        const styleObj = {
            width: '196px',
            height: '94px',
            padding: '10px 34px',
        };

        const slideimg = (item, index) => {
            return (
                <div className="box" style={styleObj}>
                    <a href="#!" key={index}>
                        <img src={item[0]} alt="" />
                    </a>
                    <a href="#!" key={index}>
                        <img src={item[1]} alt="" />
                    </a>
                    <a href="#!" key={index}>
                        <img src={item[2]} alt="" />
                    </a>
                    <a href="#!" key={index}>
                        <img src={item[3]} alt="" />
                    </a>
                </div>
            );
        };

        const sliderTmpl = function sliderTmpl(item) {
            return <div className={styles.swiper}>{slideimg(item)}</div>;
        };

        const sliderConfig = {
            arrows: true,
            autoplay: false,
            dots: false,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            // unitSize: 1000,
            speed: 0,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.dot_current,
            sliderTmpl,
        };

        return (
            <div>
                {/* {this.state.isData ? ( */}
                <div className={styles.mod_house_box}>
                    <div className={styles.item}>
                        {xNav}
                        {content.textLink.map((item, index) => {
                            return title(item, index);
                        })}
                    </div>
                    {hover ? (
                        <div className={styles.scroll}>
                            <Slides content={slideArr} config={sliderConfig} />
                        </div>
                    ) : null}
                </div>
                {/* ) : null} */}
            </div>
        );
    }
}

export default errorBoundary(Xlist);
