import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Homenews 组件
 */
class Homenews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: '',
            // data: [],
        };
    }

    async componentDidMount() {}

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const homelist = (item, index) => {
            if (index === 0) {
                // return item.type === 'ad' ? (
                //     <div className={styles.headbox} key={index}>
                //         <div ref={item.ref} />
                //     </div>
                // ) : (
                return (
                    <div className={styles.headbox} key={index}>
                        <div className={styles.mod_headline}>
                            <a href={item.url} target="_blank" className={styles.headline} rel={rel}>
                                {item.title}
                            </a>
                            <div key={index} index={index} className={styles.mod_list}>
                                <div className={styles.list_img}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.thumbnail} style={{ width: 230, height: 140 }} />
                                    </a>
                                </div>
                                <div className={styles.list_info}>
                                    <p className={styles.diff}>{item.summary}</p>
                                </div>
                                <p className={styles.box}>
                                    <span className={styles.time}>发表于{item.newsTime}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                );
                // );
            } else {
                // return item.type === 'ad' ? (
                //     <div className={styles.border_top} key={index}>
                //         <div ref={item.ref} />
                //     </div>
                // ) : (
                return (
                    <div key={index} className={styles.border_top}>
                        <div className={styles.list_img}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={item.thumbnail} style={{ width: 230, height: 140 }} />
                            </a>
                        </div>
                        <div className={styles.list_info}>
                            <p className={styles.title}>
                                <a href={item.url} target="_blank" rel={rel}>
                                    {item.title}
                                </a>
                            </p>
                            <p className={styles.detail}>{item.summary}</p>
                        </div>
                        <p className={styles.box}>
                            <span className={styles.time}>发表于{item.newsTime}</span>
                        </p>
                    </div>
                );
                // );
            }
        };

        return (
            <div className={styles.homenews}>
                {content.map((item, index) => {
                    return homelist(item, index);
                })}
            </div>
        );
    }
}

export default errorBoundary(Homenews);
