import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 BottomAffix 组件
 */
class BottomAffix extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { floatAd1, floatAd2 },
        } = this.props;

        const bottomAffix = (
            <React.Fragment key="adBotttom">
                <Ad content={floatAd1} styleName={styles.floatAd} />
                <Ad content={floatAd2} styleName={styles.floatAd} />
            </React.Fragment>
        );

        return [bottomAffix];
    }
}

export default errorBoundary(BottomAffix);
