import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../../../components/recommend';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Ad from '@ifeng-fe/ui_pc_ad';

/**
 * 定义 Todyhot 组件
 */

class Todyhot extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        num: PropTypes.number,
        id: PropTypes.string,
        title: PropTypes.string,
        ad: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            hasImg: false,
        };
    }

    UNSAFE_componentWillMount() {
        const constentList = [];
        const listImg = [];
        const listTxt = [];

        // console.log('fun——————', this.props.content);

        for (const i in this.props.content) {
            constentList.push(this.props.content[i]);
        }
        listImg.push(constentList[1][0]);
        listTxt.push(constentList[1][1]);

        if (listImg?.[0] && listImg?.[0].length > 0 && listImg?.[0]?.[0]?.thumbnails?.image?.[0]?.url) {
            this.setState({
                hasImg: true,
            });
        } else {
            console.log(`第${this.props.num}个 todyhot 组件模块数据没有图片，已隐藏第一条数据`);

            return;
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { content, num } = this.props;

        const constentList = [];
        const listImg = [];
        const listTxt = [];

        for (const i in content) {
            constentList.push(content[i]);
        }
        listImg.push(constentList[1][0]);
        listTxt.push(constentList[1][1]);

        const itemsimg = (item, index) => {
            if (item) {
                return (
                    item?.thumbnails?.image?.[0]?.url && (
                        <div key={index}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={item.thumbnails.image[0].url} alt="" width="260" height="160" />
                            </a>
                            <a href={item.url} className={styles.lm_txt} target="_blank" rel={rel}>
                                {item.title2 ? item.title2 : item.title}
                            </a>
                        </div>
                    )
                );
            } else {
                return;
            }
        };

        const itemslist = (item, index) => {
            return (
                <li key={index}>
                    <a href={item.url} target="_blank" rel={rel}>
                        {item.title2 ? item.title2 : item.title}
                    </a>
                </li>
            );
        };

        return (
            <div>
                <div className={this.props.num === 1 ? styles.diff : styles.mod_lm}>
                    <div className={styles.mod_lm_title}>
                        <Chip
                            id={this.props.id}
                            type="static"
                            title={this.props.title}
                            groupName="导航"
                            content={constentList[0]}>
                            <Recommend content={constentList[0]} />
                        </Chip>
                        {/* 洞见有广告 */}
                        {this.props.ad ? <Ad content={this.props.ad} styleName={styles.Ad} /> : null}
                    </div>
                    <div>
                        {this.state.hasImg ? (
                            <div className={styles.lm_pic}>
                                {listImg[0].map((item, index) => {
                                    return itemsimg(item, index);
                                })}
                            </div>
                        ) : null}
                        <div className={styles.lm_link}>
                            <ul>
                                {listTxt[0].map((item, index) => {
                                    return itemslist(item, index);
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Todyhot);
