import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../../../components/recommend';
import Xlogo from './xlogo';
import Xlist from './xlist';
/**
 * 定义 Xcenter 组件
 */

class Xcenter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 2,
        };
    }

    onMouseEnter(index) {
        this.setState({
            currentIndex: index,
        });
    }

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { xuancenter, xlist1, xlist2, xlist3, xlist4, xlist5 },
        } = this.props;

        const {
            state: { currentIndex },
            onMouseEnter,
        } = this;

        const xlogodata = {
            xuancenter,
        };

        return (
            <div className={styles.differ}>
                <Chip id="30060" type="static" title="选材中心logo" groupName="右边栏目选材中" content={xlogodata}>
                    <Xlogo />
                </Chip>
                <div className={styles.mod_house} onMouseEnter={onMouseEnter.bind(this, 1)}>
                    <Chip id="45028" type="struct" title="选材中心list1" groupName="右边栏目选材中" content={xlist1}>
                        <Xlist num={1} currentIndex={currentIndex} />
                    </Chip>
                </div>
                <div className={styles.mod_house} onMouseEnter={onMouseEnter.bind(this, 2)}>
                    <Chip id="45029" type="struct" title="选材中心list2" groupName="右边栏目选材中" content={xlist2}>
                        <Xlist num={2} currentIndex={currentIndex} />
                    </Chip>
                </div>
                <div className={styles.mod_house} onMouseEnter={onMouseEnter.bind(this, 3)}>
                    <Chip id="45030" type="struct" title="选材中心list3" groupName="右边栏目选材中心" content={xlist3}>
                        <Xlist num={3} currentIndex={currentIndex} />
                    </Chip>
                </div>
                <div className={styles.mod_house} onMouseEnter={onMouseEnter.bind(this, 4)}>
                    <Chip id="45031" type="struct" title="选材中心list4" groupName="右边栏目选材中" content={xlist4}>
                        <Xlist num={4} currentIndex={currentIndex} />
                    </Chip>
                </div>
                <div className={styles.mod_house} onMouseEnter={onMouseEnter.bind(this, 5)}>
                    <Chip id="45032" type="struct" title="选材中心list5" groupName="右边栏目选材中" content={xlist5}>
                        <Xlist num={5} currentIndex={currentIndex} />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Xcenter);
