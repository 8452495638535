import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../components/recommend';

class Cooperation extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <div>
                <Recommend content={content.cooperation} />
            </div>
        );
    }
}

export default errorBoundary(Cooperation);
