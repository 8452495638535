import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Todyhot from './todyhot/';
import Xcenter from './xcenter';
import Ranklive from './ranklive';
import Backtop from './backtop';
import Ad from '@ifeng-fe/ui_pc_ad';

/**
 * 定义 Rightmain 组件
 */

class Rightmain extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: {
                todyhotlogo,
                todyhot,
                dongjianlogo,
                dongjianhot,
                lifelogo,
                lifehot,
                qxjlogo,
                qxjhot,
                sjxwclogo,
                sjxwchot,
                funlogo,
                funhot,
                xuancenter,
                xlist1,
                xlist2,
                xlist3,
                xlist4,
                xlist5,
                hwtjlogo,
                hwtjhot,
                rank48,
                sizeW300H200Ad1,
                sizeW300H200Ad2,
                sizeW300H200Ad3,
                dongjianAd,
                liveAd,
            },
        } = this.props;

        const todyhotdata = {
            todyhotlogo,
            todyhot,
        };

        const dongjiandata = {
            dongjianlogo,
            dongjianhot,
            // dongjianAd,
        };

        const lifedata = {
            lifelogo,
            lifehot,
        };

        const qxjdata = {
            qxjlogo,
            qxjhot,
        };

        const sjxwcdata = {
            sjxwclogo,
            sjxwchot,
        };

        const fundata = {
            funlogo,
            funhot,
        };

        const xlistdata = {
            xuancenter,
            xlist1,
            xlist2,
            xlist3,
            xlist4,
            xlist5,
        };

        const haowudata = {
            hwtjlogo,
            hwtjhot,
        };

        const rankdata = {
            rank48,
        };

        // console.log('funlogo__', fundata);

        return (
            <div>
                <Todyhot content={todyhotdata} num={1} id={'30053'} title={'家居右侧-今日热点导航'} />
                <Todyhot content={dongjiandata} num={2} id={'30054'} title={'家居右侧-洞见导航'} ad={dongjianAd} />
                <Ad content={sizeW300H200Ad1} styleName={styles.Ad} />
                <Todyhot content={lifedata} num={3} id={'30055'} title={'家居右侧-生活研究院导航'} ad={liveAd} />
                <Todyhot content={qxjdata} num={4} id={'30056'} title={'家居右侧-前行者'} />
                <Ad content={sizeW300H200Ad2} styleName={styles.Ad} />
                <Todyhot content={sjxwcdata} num={5} id={'30057'} title={'家居右侧-设计下午茶导航'} />
                <Todyhot content={fundata} num={6} id={'30058'} title={'家居右侧-FUN新家导航'} />
                <Ad content={sizeW300H200Ad3} styleName={styles.Ad} />
                <Xcenter content={xlistdata} />
                <Todyhot content={haowudata} num={7} id={'30059'} title={'家居右侧-好物推荐导航'} />
                <Ranklive content={rank48} />
                <Backtop />
            </div>
        );
    }
}

export default errorBoundary(Rightmain);
