import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';
// import { list } from '../../../../../../../biz/controllers/pc/liveHome';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/handleAd';
import { Event } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';

/**
 * 定义 Hotnewsauto 组件
 */
class Hotnewsauto extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
        // recommendHotnewsLen: PropTypes.array,
    };

    state = {
        data: [],
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 插入软广
        try {
            const { ad } = this.props;

            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         // event 为传入的 event 事件对象，用于广告方绑定事件
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', () => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [];

            //             insert(insertArr, replaceArr, dom => {
            //                 console.log('dom__', dom);
            //             });
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            const data = this.props.content;

            // console.log('data__', data);
            // const recommendHotnews = this.props.recommendHotnewsLen;

            // console.log('data__', data);
            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;
        const slideArr = [];
        const textArr = [];

        // console.log('data__', data);

        const createList = data => (
            <div className={styles.mod_container_list}>
                {data.map((item, index) => {
                    if (item.type === 'ad') {
                        return (
                            <div className={styles.mod_list} key={index}>
                                <div ref={item.ref} />
                            </div>
                        );
                    } else if (Number(item.thumbnailsCount) === 3) {
                        return (
                            <div className={styles.border_bottom} key={index}>
                                <p className={styles.title}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        {item.title}
                                    </a>
                                </p>
                                <ul className={styles.list}>
                                    {item.thumbnails.image.map((itemc, indexc) => (
                                        <li key={indexc}>
                                            <a href={item.url} target="_blank" rel={rel}>
                                                <img src={itemc.url} alt="" width="230" height="140" />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className={styles.border_top}>
                                <div className={styles.list_img}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.thumbnail} style={{ width: 230, height: 140 }} />
                                    </a>
                                </div>
                                <div className={styles.list_info}>
                                    <p className={styles.title}>
                                        <a href={item.url} target="_blank" rel={rel}>
                                            {item.title}
                                        </a>
                                    </p>
                                    <p className={styles.detail}>{item.summary}</p>
                                </div>
                                <p className={styles.box}>
                                    <span className={styles.time}>发表于{item.newsTime}</span>
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
        );

        return <div>{createList(data)}</div>;
    }
}

export default errorBoundary(Hotnewsauto);
