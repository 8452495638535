import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Navigation extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const navigation = content.map((item, index) => (
            <li key={index}>
                <a href={item.url} target=" _blank" rel={rel} title={item.title}>
                    {item.title}
                </a>
            </li>
        ));

        return (
            <div id="navigation" className={styles.box_nav}>
                <div className={styles.nav_main}>
                    <div className={styles.col_w1000}>
                        <div className={styles.zhuangxiu} />
                        <ul>{navigation.slice(0, 4)}</ul>
                        <div className={styles.shenghuo} />
                        <ul>{navigation.slice(4, 8)}</ul>
                        <div className={styles.sheji} />
                        <ul>{navigation.slice(8, 12)}</ul>
                        <div className={styles.cehua} />
                        <ul>{navigation.slice(12, 16)}</ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Navigation);
