import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import Navigation from './navigation';
import NavShare from './navShare';
import Content from './content';
import Branddy from './branddy/';
import Cooperation from './cooperation';
import Footer from '@ifeng-fe/container_channel/common/client/winter/components/footer/';
import BottomAffix from './bottomAffix';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    /**
     * 渲染网页布局
     */
    render() {
        /**
         * 组件分发数据
         */

        const {
            content: {
                headAd,
                sliderAd,
                topAd,
                nav,
                search,
                logo,
                logoAd,
                navigation,
                navigation2,
                topslide,
                navTagAd1,
                bannerTagAd1,
                homenews,
                hotnewsauto,
                todyhotlogo,
                todyhot,
                dongjianlogo,
                dongjianhot,
                lifelogo,
                lifehot,
                qxjlogo,
                qxjhot,
                sjxwclogo,
                sjxwchot,
                funlogo,
                funhot,
                xuancenter,
                xlist1,
                xlist2,
                xlist3,
                xlist4,
                xlist5,
                hwtjlogo,
                hwtjhot,
                rank48,
                branddy,
                cooperation,
                footer,
                floatAd1,
                floatAd2,
                sizeW300H200Ad1,
                sizeW300H200Ad2,
                sizeW300H200Ad3,
                newsStreamAd,
                bottom1Ad,
                dongjianAd,
                liveAd,
            },
        } = this.props;

        const headerData = {
            headAd,
            nav,
            search,
            topAd,
            logo,
            logoAd,
        };

        const contentData = {
            topslide,
            sliderAd,
            navTagAd1,
            bannerTagAd1,
            homenews,
            hotnewsauto,
            todyhotlogo,
            todyhot,
            dongjianlogo,
            dongjianhot,
            lifelogo,
            lifehot,
            qxjlogo,
            qxjhot,
            sjxwclogo,
            sjxwchot,
            funlogo,
            funhot,
            xuancenter,
            xlist1,
            xlist2,
            xlist3,
            xlist4,
            xlist5,
            hwtjlogo,
            hwtjhot,
            rank48,
            sizeW300H200Ad1,
            sizeW300H200Ad2,
            sizeW300H200Ad3,
            newsStreamAd,
            bottom1Ad,
            dongjianAd,
            liveAd,
        };

        const branddydata = {
            branddy,
        };

        const cooperationdata = {
            cooperation,
        };

        const bottomAffixData = {
            floatAd1,
            floatAd2,
        };

        return (
            <div>
                <Header content={headerData} />

                <Chip id="45025" type="struct" title="一级导航" groupName="导航栏" content={navigation}>
                    <Navigation />
                </Chip>
                <Chip id="45026" type="struct" title="二级导航" groupName="导航栏2" content={navigation2}>
                    <NavShare ad={navTagAd1} />
                </Chip>
                <Content content={contentData} />
                <Branddy content={branddydata} />
                <Ad content={bottom1Ad} styleName={styles.bottom1Ad} />
                <Chip id="30061" type="static" title="合作媒体" groupName="合作媒体" content={cooperationdata}>
                    <Cooperation />
                </Chip>
                <Chip id="20012" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                    <Footer />
                </Chip>
                <BottomAffix content={bottomAffixData} />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
