import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Leftmain from './leftmain/';
import Rightmain from './rightmain/';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * for this page
 */

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        return (
            <div className={styles.home_main}>
                <div className={styles.left_main}>
                    <Leftmain content={content} />
                </div>
                <div className={styles.right_main}>
                    <Rightmain content={content} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Content);
