import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Backtop extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            backtop: false,
        };
    }

    backtopf() {
        let timer = setInterval(() => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-scrollTop / 6);

            if (scrollTop === 0) {
                clearInterval(timer);
            }

            document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed;
        }, 30);
    }

    componentDidMount() {
        window.addEventListener(
            'scroll',
            () => {
                const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;

                if (scrollHeight > 500) {
                    this.setState({
                        backtop: true,
                    });
                } else {
                    this.setState({
                        backtop: false,
                    });
                }
            },
            false,
        );
    }

    // /**
    //  * 渲染网页布局
    //  */

    render() {
        const {
            state: { backtop },
            backtopf,
        } = this;

        return (
            <div className={styles.topback}>
                {backtop ? (
                    <img
                        src="https://y1.ifengimg.com/home/image/p_1_42.png"
                        width="65"
                        height="65"
                        onClick={backtopf.bind(this)}
                    />
                ) : null}
            </div>
        );
    }
}

export default errorBoundary(Backtop);
