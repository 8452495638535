import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
// import Ad from '@ifeng-fe/ui_pc_ad';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Branddy 组件
 */

class Branddy extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { branddy },
        } = this.props;

        const list = branddy.map(
            (item, index) =>
                item?.thumbnails?.image?.[0]?.url && (
                    <li key={index}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnails.image[0].url} alt="" width="230" height="140" />
                        </a>
                        <div className={styles.bg} />
                        <a href={item.url} target="_blank" rel={rel} className={styles.info}>
                            {item.title2 ? item.title2 : item.title}
                        </a>
                    </li>
                ),
        );

        return (
            <div className={styles.eight_img}>
                <ul className={styles.box_list}>{list}</ul>
            </div>
        );
    }
}

export default errorBoundary(Branddy);
