import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Recommend from '../../../../components/recommend';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Ranklive extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    addPreZero = num => {
        if (num < 10) {
            return `0${num}`;
        } else {
            return num;
        }
    };

    // /**
    //  * 渲染网页布局
    //  */
    render() {
        //     /**
        //      * 组件分发数据
        //      */
        const { content } = this.props;

        // const rankTalkInfo = content.rankTalkInfo;

        const rankTalkFeed = content.map((item, index) => (
            <li key={index}>
                <span>{this.addPreZero(index + 1)}</span>
                <a href={item.url} target="_blank" rel={rel}>
                    {item.title}
                </a>
            </li>
        ));

        return (
            <div className={styles.mod_lm}>
                <div className={styles.mod_lm_title}>
                    <div className="lt-left">
                        <h3 className="title">48小时点击排行</h3>
                    </div>
                </div>
                <div className={styles.mod_phb}>
                    <ul>{rankTalkFeed}</ul>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Ranklive);
