import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
// import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Topslide from './topslide/';
import Homenews from './homenews';
import Hotnewsauto from './hotnewsauto';
import Ad from '@ifeng-fe/ui_pc_ad';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Leftmain 组件
 */

class Leftmain extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { bannerTagAd1, sliderAd, topslide, homenews, hotnewsauto, newsStreamAd },
        } = this.props;
        const listData = [...homenews, ...hotnewsauto];

        // console.log('listdata___', listData);

        return (
            <div>
                <Ad content={bannerTagAd1} />
                <Chip id="60063" type="recommend" title="焦点图" groupName="焦点图" content={topslide}>
                    <Topslide ad={sliderAd} />
                </Chip>
                <Chip id="60064" type="recommend" title="推荐位5条" groupName="家居要闻" content={listData}>
                    {/* <Homenews /> */}
                    <Hotnewsauto content={listData} ad={newsStreamAd} />
                </Chip>

                <a href="//home.ifeng.com/shanklist/32-60103-" target="_blank" className={styles.more} rel={rel}>
                    查看更多
                </a>
            </div>
        );
    }
}

export default errorBoundary(Leftmain);
