import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/handleAd';
import { Event } from '@ifeng-fe/ui_base';

/**
 * 定义 Topslide 组件
 */
class Topslide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    state = {
        data: [],
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 插入软广
        try {
            const { ad } = this.props;

            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         // event 为传入的 event 事件对象，用于广告方绑定事件
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', (data) => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [3];

            //             const replaceArr = [];

            //             insert(insertArr, replaceArr, (dom) => {
            //                 console.log('dom__', dom);
            //             });
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            const data = this.props.content;

            // console.log('data__', data);
            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;

        const sliderTmpl = function sliderTmpl(item) {
            return item.type === 'ad' ? (
                <div className={styles.swiper}>
                    <div ref={item.ref} />
                </div>
            ) : (
                <div className={styles.swiper}>
                    <a
                        className={styles.blockA}
                        href={item.url}
                        target="_blank"
                        alt={item.title}
                        title={item.title}
                        rel={rel}>
                        <img src={item.thumbnail} style={{ width: 710, height: 345 }} />
                    </a>
                    <div className={styles.info}>
                        <div className={styles.f_word}>{item.title}</div>
                    </div>
                </div>
            );
        };

        const sliderConfig = {
            arrows: 'hover',
            autoplay: false,
            dots: true,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            // unitSize: 1000,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.dot_current,
            sliderTmpl,
        };

        return (
            <div className={styles.focus_img}>
                <Slides content={data} config={sliderConfig} />
            </div>
        );
    }
}

export default errorBoundary(Topslide);
